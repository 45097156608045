import { UserInfoCard } from "bungie-api-ts/user"
import React from "react"
import { getMembershipIcon } from "./DataHelpers"
import { getOptionLabel } from "./searchUtils"
import { Box } from "@mui/material"

export interface SearchResultEntryProps {
    option : string | UserInfoCard
    props : React.HTMLAttributes<HTMLLIElement>
}

const ReportSearchBarResultEntry = ({option, props} : SearchResultEntryProps) => {
    if(typeof option === 'string'){
        // no results
        return (
            <Box className="report-toolbar-searchbar-result-entry-container">
                <Box className="report-toolbar-searchbar-result-entry-contents">
                    <Box className="report-toolbar-searchbar-result-entry-nousers">
                        {option}
                    </Box>
                </Box>
            </Box>
        )
    }
    return (
        <Box className="report-toolbar-searchbar-result-entry-container">
                <li {...props}>
                    <Box className="report-toolbar-searchbar-result-entry-contents">
                        <Box className="report-toolbar-searchbar-result-entry-platform-icon" component="img" src={getMembershipIcon(option.membershipType)}/>
                        <Box className="report-toolbar-searchbar-result-entry-username">{getOptionLabel(option)}</Box>
                    </Box>
                </li>
        </Box>
    )
}

export default ReportSearchBarResultEntry