import { UserInfoCard, UserSearchResponse, UserSearchResponseDetail } from "bungie-api-ts/user";
import { searchPlayerByName, searchPlayersByPrefix } from "./RequestHelpers";
import { formatBungieNameFromUserInfoCard, formatLinkFromUserInfoCard } from "./DataHelpers";

const fullBungieNameMatch = /(.+?)#([0-9]{4})$/;

const getPrimaryProfile = (searchResult : UserSearchResponseDetail) => {
    const crossSaveProfile = searchResult.destinyMemberships.find(m => m.crossSaveOverride === m.membershipType);
    if(crossSaveProfile){
        return [crossSaveProfile];
    }else{
        // multiple profiles but no cross-save, return them all 
        // the user can identify them by platform icon in the search menu anyway
        return searchResult.destinyMemberships
    }
}

export const searchUser = async (searchValue : string) : Promise<UserInfoCard[]> => {
    const matches = fullBungieNameMatch.exec(searchValue);
    if (matches && matches.length === 3) {
        const name = matches[1];
        const code = matches[2];
        const response = await searchPlayerByName(name, code)
        if(response && response.status === 200){
            const profiles = response.data.Response as UserInfoCard[]
            if(profiles.length === 1){
                return profiles;
            }else{
                const crossSaveProfile = profiles.find(p => p.membershipType === p.crossSaveOverride)
                if(crossSaveProfile){
                    return [crossSaveProfile]
                }else{
                    return profiles;
                }
            }
        }
    } else {
        const response = await searchPlayersByPrefix(searchValue)
        if(response && response.status === 200){
            const results : UserInfoCard[] = []
            const data = response.data.Response as UserSearchResponse;
            for (const user of data.searchResults){ // all matching users for the prefix query
                results.push(...getPrimaryProfile(user))
            }
            return results;
        }
    }
    return []
}

export const getOptionLabel = (option : string | UserInfoCard) => {
    return typeof option === 'string' ? option : formatBungieNameFromUserInfoCard(option)
}

export const generateOptionKey = (option : string | UserInfoCard) => {
    return typeof option === 'string' ? option : formatLinkFromUserInfoCard(option)
}