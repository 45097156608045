import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import App from './App';

axios.defaults.baseURL = 'https://www.bungie.net'
axios.defaults.headers['X-API-Key'] = process.env.REACT_APP_BNG_API_KEY || '';
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);