import { DataTypes } from "@pattern.report/loot-tables";
import { DestinyRecordComponent } from "bungie-api-ts/destiny2";
import { sortPatternDBBySource } from "./DataHelpers";
import ReportSet from "./ReportSet";
import { Grid } from "@mui/material";

export interface ReportGridProps {
    patternDB :  DataTypes.PatternDB
    records ?: Record<number,DestinyRecordComponent>
}

const ReportGrid = ({patternDB, records } : ReportGridProps) => {
    const display : React.ReactElement[] = [];
    const sorted = sortPatternDBBySource(patternDB)
    
    sorted.forEach((patternSet) => {
        display.push(
            <ReportSet key={patternSet.metadata.set} records={records} set={patternSet}/>
        )
    })
    
    return (
        <Grid className="report-grid" container spacing={1}>
                    {display}
        </Grid>
    )   
}

export default ReportGrid