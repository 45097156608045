import { DestinyRecordComponent } from "bungie-api-ts/destiny2";
import { SortedPatternSet } from "./DataHelpers";
import { Box, Grid, Typography } from "@mui/material";
import { DataTypes } from "@pattern.report/loot-tables";
import WeaponIcon from "./WeaponIcon";
import WeaponInfo from "./WeaponInfo";
import WeaponSetImage from "./WeaponSetImage";

export interface ReportSetProps {
    set : SortedPatternSet;
    records ?: Record<number, DestinyRecordComponent>
}


const formatPatternProgress = (pattern : DataTypes.Pattern, records ?: Record<number,DestinyRecordComponent>) => {
    const record = records ? records[pattern.patternRecordHash] : undefined;
    // const specific = getWeaponSpecificSource(collectionsEntry.sourceHash);
    // specific ? console.log(pattern.name, season, specific) : console.warn(pattern.name, season, specific)
    // <sub>{damageType.displayProperties.name} {formatAmmoType(inventoryEntry.equippingBlock?.ammoType)} Ammunition {manifestDefinitions.DestinyInventoryItemDefinition[frameType!].displayProperties.name}{rpm ? ` (${rpm.value}rpm)` : ''} {inventoryEntry.itemTypeDisplayName}</sub>
    return (
        <Box key={pattern.patternRecordHash} className="weapon-report-container">
            <Box className="weapon-report-content">
                <WeaponIcon pattern={pattern} />
                <WeaponInfo pattern={pattern} record={record}/>
            </Box>
        </Box>
    )
}

const ReportSet = ({ set, records }: ReportSetProps) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} className="report-patternset-grid">
            <Box className="report-patternset-container">
                <Box className="report-patternset-content">
                    <Box className="report-header-container">
                        <WeaponSetImage set={set} />
                        <Box className="report-header-text">
                            <Typography variant="subtitle1">{set.metadata.set}</Typography>
                        </Box>
                    </Box>
                    {set.patterns.map(p => formatPatternProgress(p, records))}
                </Box>
            </Box>
        </Grid>
    )
}

export default ReportSet