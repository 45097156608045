import './Home.css';

import { Box, Grid, Typography } from '@mui/material';
import ReportSearchBar from './ReportSearchBar';

const Home = () => {
    document.title = "pattern.report"
    return (
        <Box className="home-root">
            <Grid container className="home-container" columns={5} maxWidth={2000}>
                <Grid item md={3} sm={5}>
                    <Box className="home-content-container">
                        <Box className="home-content-inner">
                            <Box className="home-content-title">
                                <Box className="home-content-logo" component="img" src="./assets/pattern_logo.png"/>
                                <Typography className="home-content-title-text" variant="h2">pattern.report</Typography>
                            </Box>
                            <Box className="home-content-divider"/>
                            <Box className="home-searchbar-container">
                                <ReportSearchBar/>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={2} sm={0} bgcolor={"black"} sx={{display : {md: 'block', sm: 'none', xs: 'none'}}}>
                    <Box className="home-background-image-container">
                    <Box className="home-background-image" component="img" src="./assets/home.jpg"/>    
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Home;
