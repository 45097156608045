import { Box } from "@mui/material"
import { DataTypes } from "@pattern.report/loot-tables"
import { formatImageUrl } from "./DataHelpers"

export interface WeaponIconProps {
    pattern: DataTypes.Pattern
}

const WeaponIcon = ( {pattern} : WeaponIconProps) => {
    return (
        <Box className="weapon-icon-container">
            <Box className="weapon-icon" component="img" alt={pattern.name} src={formatImageUrl(pattern.data.icon)}></Box>
            <Box className="weapon-watermark" component="img" src={formatImageUrl(pattern.data.watermark)}></Box>
            <Box className="weapon-ammo-icon" component="img" src={formatImageUrl(pattern.data.ammo.icon)}></Box>
        </Box>
    )
}

export default WeaponIcon