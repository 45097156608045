import { Box } from "@mui/material";
import { SortedPatternSet, getActivityImage } from "./DataHelpers";

export interface WeaponSetImageProps {
    set : SortedPatternSet;
}

const WeaponSetImage = ({ set }: WeaponSetImageProps) => {
    return (
        <Box className="weaponset-image-container">
            <Box className="weaponset-image" component="img" src={getActivityImage(set.metadata)}></Box>
        </Box>
    )
}

export default WeaponSetImage