
import { useParams } from "react-router-dom";
import { getLinkedProfiles, getProfileRecordComponents } from "./RequestHelpers";
import { DestinyLinkedProfilesResponse, DestinyProfileResponse, DestinyProfileUserInfoCard, DestinyRecordComponent } from "bungie-api-ts/destiny2";
import { useEffect, useState } from "react";
import { DataTypes } from "@pattern.report/loot-tables";
import { formatBungieNameFromUserInfoCard } from "./DataHelpers";
import { Box, Container } from "@mui/material";
import ReportHeader from "./ReportHeader";
import ReportGrid from "./ReportGrid";

export interface ReportProps {
    patternDB :  DataTypes.PatternDB
}


const Report = ({ patternDB }: ReportProps) => {
    const { membershipType, membershipId } = useParams();
    const [profile, setProfile] = useState<DestinyProfileUserInfoCard>()
    const [records, setRecords] = useState<Record<number, DestinyRecordComponent>>({})
    const [username, setUsername] = useState<string>()


    const resetProfile = () => {
        setProfile(undefined)
        setRecords({})
        setUsername(undefined)
    }

    useEffect(() => {
        resetProfile()

        const fetchProfiles = async (type : string, id : string) => {
            const response = await getLinkedProfiles(type, id);
            if(response.status === 200){
                const data = response.data.Response as DestinyLinkedProfilesResponse;
                const matchingProfile = data.profiles.find(p => p.membershipType === Number(type))
                if(matchingProfile) setProfile(matchingProfile)
            }
        }

        const fetchRecords = async (type : string, id : string) => {
            const response = await getProfileRecordComponents(type, id)
            if(response.status === 200){
                const data = response.data.Response as DestinyProfileResponse;
                const recordData = data.profileRecords.data?.records;
                const characterRecords = data.characterRecords.data
                let mergedRecords : Record<number, DestinyRecordComponent> = {}
                if(recordData){
                    mergedRecords = {...recordData}
                }
                // catch patterns which for some reason are not in profile, but instead in character
                if (characterRecords) {
                    Object.values(characterRecords).forEach(
                        c => mergedRecords = {...mergedRecords, ...c.records}
                    )
                }
                setRecords(mergedRecords)
            }
        }

        if(membershipType && membershipId) {
            fetchProfiles(membershipType, membershipId).catch(
                console.error
            )
            fetchRecords(membershipType, membershipId).catch(
                console.error
            )
        }
    }, [membershipType, membershipId])

    useEffect(() => {
        if(profile){
            setUsername(formatBungieNameFromUserInfoCard(profile))
            document.title = `${username} | pattern.report`
        }
    }, [profile, username])

    return (
        <Box className="report-root">
            <ReportHeader user={username}/>
            <Container className="report-container" maxWidth="lg">
                <ReportGrid patternDB={patternDB} records={records}/>
            </Container>
        </Box>
    );
}

export default Report