import './App.css';
import Report from './Report';
import Home from './Home';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { DataTypes } from '@pattern.report/loot-tables/';
import _db from '@pattern.report/loot-tables/db.json'

const App = () => {
    const patternDB : DataTypes.PatternDBExport = _db;

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <Home />
            )
        },
        {
            path: '/:membershipType/:membershipId',
            element: (
                <Report patternDB={patternDB.db}/>
            )
        },
        {
            path: '*',
            element: (
                <Navigate to="/"/>
            )
        }
    ])

    return (
        <RouterProvider router={router} />
    );
}

export default App;
