
import { AppBar, Box, Skeleton, Toolbar, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import ReportSearchBar from "./ReportSearchBar"

export interface ReportHeaderProps {
    user ?: string
}

const ReportHeader = ({user} : ReportHeaderProps) => {
    return (
        <Box>
            <AppBar className="report-header" position="static" sx={{backgroundColor: "#263238", color: "#ECEFF1"}}>
                <Box className="report-toolbar-container" sx={{maxWidth:"lg"}}>
                    <Toolbar className="report-toolbar">
                        <Box className="report-toolbar-logo-container">
                            <Link className="link-invisible" to="/">
                                <Box className="report-toolbar-logo" component="img" src="/assets/pattern_logo.png"/>
                            </Link>
                        </Box>
                        <Box className="report-toolbar-username-container">
                            <Typography variant="h6">
                                {user ? user : <Skeleton width="150px" height="2.8rem"/>}
                            </Typography>
                        </Box>
                        <Box className="report-toolbar-searchbar-container">
                            <ReportSearchBar/>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
        </Box>
    )
}

export default ReportHeader