import axios from "axios";

export const getGeneric = async (path : string, includeAPIKey = true) => {
    const headers = includeAPIKey ? {} : {'X-API-Key' : undefined}
    return await axios.get(path, {headers : headers});
}

export const getDestinyManifest = async () => {
    return await axios.get('/Platform/Destiny2/Manifest/');
}

export const searchPlayerByName = async (name : string, code : string) => {
    return await axios.post('/Platform/Destiny2/SearchDestinyPlayerByBungieName/-1/', {displayName : name, displayNamecode : code})
}

export const searchPlayersByPrefix = async (prefix : string) => {
    return await axios.post('/Platform/User/Search/GlobalName/0/', {displayNamePrefix : prefix})
}

export const getBungieNetUserById = async (id : string) => {
    return await axios.get(`/Platform/User/GetBungieNetUserById/${id}/`)
}

export const getLinkedProfiles = async (membershipType : string, membershipId : string) => {
    return await axios.get(`/Platform/Destiny2/${membershipType}/Profile/${membershipId}/LinkedProfiles/`)
}

export const getProfileRecordComponents = async (membershipType : string, membershipId : string) => {
    return await axios.get(`/Platform/Destiny2/${membershipType}/Profile/${membershipId}/?components=700,900`)
}