import { Autocomplete, Box, CircularProgress, InputBase, Paper, PaperProps } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { UserInfoCard } from "bungie-api-ts/user"
import { Search } from "@mui/icons-material"
import ArrowForward from '@mui/icons-material/ArrowForward'
import { generateOptionKey, getOptionLabel, searchUser } from "./searchUtils"
import ReportSearchBarResultEntry from "./ReportSearchBarResultEntry"
import { useNavigate } from "react-router-dom"
import { formatLinkFromUserInfoCard } from "./DataHelpers"

type SearchResult = UserInfoCard | string;

const ReportSearchBar = () => {
    const [options, setOptions] = useState<SearchResult[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const acRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(searchValue){
            setLoading(true)
            searchUser(searchValue).then(
                (results) => {
                    if(results.length) {
                        setOptions(results)
                        acRef.current?.focus()
                    }else{
                        // no results
                        setOptions(['No users found'])
                    }
                    setLoading(false)
                }).catch((error) => {
                    console.error(error)
                    setLoading(false)
                })
        } 
    }, [searchValue])

    const submit = () =>{
        setSearchValue(inputValue)
    }

    const reset = () => {
        setSearchValue('')
        setInputValue('')
        setOptions([])
    }

    const handleKeyDown = (event : React.KeyboardEvent) => {
        if(event.key === 'Enter'){
            submit()
        }
    }

    const CustomPaper = (props : PaperProps) => {
        return <Paper {...props} className="report-toolbar-searchbar-paper" sx={{backgroundColor: '#37474F'}}/>
    }

    const getEndIcon = () => {
        if(loading){
            return (
                <CircularProgress className="report-toolbar-end-icon" color="inherit" size={'1.2rem'} sx={{mr:"4px"}}/>
            )
        }else{
            return (
                <ArrowForward className="report-toolbar-end-icon" onClick={submit}/>
            )
        }
    }

    return (
        <Box className="report-toolbar-searchbar-inner-container">
            <Search className="report-toolbar-searchbar-icon"/>
            <Autocomplete 
                blurOnSelect
                disableClearable
                className="report-toolbar-searchbar"
                filterOptions={(x) => x} 
                freeSolo
                PaperComponent={CustomPaper}
                value={inputValue}
                onInputChange={(event, newValue) => {
                    if(newValue){
                        setInputValue(newValue)
                        setOptions([])
                    }
                }}
                renderInput={(params) => {
                    const {InputLabelProps, InputProps, ...rest} = params;
                    return (       
                            <InputBase 
                                placeholder="Search by Bungie username..."
                                {...params.InputProps} {...rest}
                                sx={{color: '#CFD8DC'}}
                                inputRef={acRef}
                            />
                            
                    )
                } } 
                getOptionLabel={getOptionLabel}
                options={options}        
                noOptionsText="No results"
                onKeyDown={handleKeyDown}
                renderOption={(props, option) => {
                    return (
                        <ReportSearchBarResultEntry key={generateOptionKey(option)} props={props} option={option} />
                    )
                }}
                onChange={(event, newValue, reason) => {
                    if(reason === "selectOption" && typeof newValue !== "string"){
                        navigate(formatLinkFromUserInfoCard(newValue))
                        reset() // reset searchbox when we select new user to navigate to
                    }
                }}      
                openOnFocus
            />
            <Box className="report-toolbar-end-icon-container">
                {getEndIcon()}
            </Box>
        </Box>
    )
}

export default ReportSearchBar