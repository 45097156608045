import { Box, Skeleton } from "@mui/material"
import { DestinyRecordComponent } from "bungie-api-ts/destiny2"
import React from "react";

export interface WeaponProgressBarProps {
    record ?: DestinyRecordComponent
}

const WeaponProgressBar = ( {record} : WeaponProgressBarProps) => {
    const display : React.ReactElement[] = [];
    if(record){
        const complete = record.objectives[0].progress || 0;
        const incomplete = record.objectives[0].completionValue - complete;
        for (let c=0; c<complete; c++){
            display.push(
                <Box key={c} className="weapon-progress-bar-complete"/>
            )
        }
        for (let i=0; i<incomplete; i++){
            display.push(
                <Box key={complete+i} className="weapon-progress-bar-incomplete"/>
            )
        }
    }else{
        display.push(<Skeleton key="-1" variant="rectangular" width="100%" height="100%" sx={{bgcolor: '#263238'}}/>)
        // display.push(
        //     <Box key={0} className="weapon-progress-bar-incomplete"/>
        // )
    }
    return (
        <Box className="weapon-progress-bar-container">
            {display}
        </Box>
    )
}

export default WeaponProgressBar