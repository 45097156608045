import { Box } from "@mui/material"
import { DataTypes } from "@pattern.report/loot-tables"
import { formatImageUrl } from "./DataHelpers"
import { DestinyRecordComponent } from "bungie-api-ts/destiny2"
import { ExoticItemRarity } from "@pattern.report/loot-tables/dist/lib/api-constants"
import WeaponProgressBar from "./WeaponProgressBar"

export interface WeaponInfoProps {
    pattern: DataTypes.Pattern
    record ?: DestinyRecordComponent
}

const WeaponInfo = ( {pattern, record} : WeaponInfoProps) => {
    const progress = record ? record.objectives[0].progress : 0
    const total = record ? record.objectives[0].completionValue : '?'
    return (
        <Box className="weapon-info-container">
            <Box className="weapon-info-upper-container">
                <p className="weapon-info-pattern-name">{pattern.name.toUpperCase()}</p>
                { <p className="weapon-info-progress">{progress}/{total}</p> }
            </Box>
            <Box className="weapon-subtext-container">
                <Box className="weapon-element-icon" component="img" src={formatImageUrl(pattern.data.damageType.icon)}/>
                <p className="weapon-info-pattern-subtext">{pattern.data.rarity === ExoticItemRarity ? 'Exotic ' : pattern.data.intrinsic.name + ' '}{pattern.data.type}</p>
            </Box>
            <WeaponProgressBar record={record}/>
        </Box>
    )
}

export default WeaponInfo